<template>
  <div>
    <h4 class="mb-3">About</h4>
    <img src="@/assets/pic.jpg" style="width: 80%; max-width: 160px;" />
    <h4 class="mt-4">Emi Iwado</h4>
    <p class="small">
      テクノロジーの発展とそれに伴う社会問題をテーマに,
      社会に問いかける作品制作を行う.<br />
      特に関心のある分野は, AI, バイオテクノロジー, ジェンダー.
    </p>
    <p class="small mb-5">
      東京藝術大学大学院デザイン科 Design Civics (Sputniko!) 研究室修了<br />
      東京大学理学部化学科卒業, 東京大学大学院理学系研究科化学専攻修了. 修士論文テーマは「光応答性キナーゼAktを用いたPC12細胞の運命決定制御機構の解明」.
    </p>
    <p class="small">
      Emi Iwado is a Tokyo-based artist who creates works that question society and explore themes related to the advancement of technology and its associated social issues. She has a particular interest in areas such as AI, biotechnology, and gender.
    </p>
    <p class="small">
    She completed her studies in the Design Civics Laboratory (Sputniko! Lab) at the Graduate School of Design, Tokyo University of the Arts. She holds a B.S. in Chemistry from the University of Tokyo and a M.S. in Chemistry from the Graduate School of Science at the University of Tokyo. Her master's thesis theme is 'Elucidation of the differentiation and proliferation mechanism of PC12 cell with photo-activatable Akt'.
    </p>
    <h5 class="mt-4">Exhibitions / Awards / Selections</h5>
    <p class="small">
      2023 Featured in 'Inspiration Live of Graduation Projects from Prestigious Universities', published by HIGHTONE, Hong Kong<br/>
      2022 TOKYO GEIDAI ART FES Awards: Excellence Award & The Special Jury Prize by Kengo Kuma<br/>
      2021 The Year 2121: Futures In-Sight, 21_21 DESIGN SIGHT, Tokyo<br/>
      2021 SDGs × Arts Exhibition, Tokyo University of the Arts, Tokyo<br/>
      2021 Design Civics Group 2021 Summer Exhibition, LE DECO Gallery, Tokyo<br/>
    </p>
  </div>
</template>
